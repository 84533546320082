const Android = {};

Android.scroll = {
  init: function(){

		$('.js-scroll').on('click', function(){
			let t = $(this);
			//t.addClass('active').siblings().removeClass('active');
		});

    $('a[href*="#"]:not([href="#"])').click(function() {
      if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
        let target = $(this.hash);
        target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
        if (target.length) {
          $('html, body').animate({
            scrollTop: target.offset().top - 80
          }, 500);
          return false;
        }
      }
    });

		let items = $('.js-anchor');
		let displayHeight = window.innerHeight;
    let top;
    let mobile;
    if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
      mobile = true;
    }
    if(mobile){
      top = 370;
    }else{
      top = 155;
      $('.promo').parallax({imageSrc: '/images/main-bg.jpg'});
      $('.banner').parallax({imageSrc: '/images/banner-bg.jpg'});
    }
		$(document).on('scroll', function(event){
      let screenPos = $(window).scrollTop();

      if(screenPos > 100){
        $('.cons__item').addClass('show');
      }

      for(let i = 0; i < items.length; i++){
        if (items[i].offsetTop >= screenPos - items[i].offsetHeight && items[i].offsetTop <= screenPos + 280) {
          let data = $(items[i]).attr('data-section');
          $("[data-scroll="+data+"]").addClass('active').siblings().removeClass('active');
          if(data == 2){
            console.log('phones');
            $('.big-cons__phones').addClass('show');
          }
          if(data == 3){
            $('.connect-item').addClass('show');
          }
          if(data == 4){
            $('.market__item').addClass('show');
          }
          if(data == 4 && items[i].offsetTop  < screenPos - top){
            $('.banks__item').addClass('show');
            $("[data-scroll='5']").addClass('active').siblings().removeClass('active');
          }
        }
      }
		});
  }
};


Android.menu = {
  init: function(){
    $('.js-menu-btn').on('click', function(){
      let t = $(this);
      t.toggleClass('active');
      $('.js-menu').toggleClass('active');
    });
    $('.js-scroll').on('click', function(){
      $('.js-menu').removeClass('active');
      $('.js-menu-btn').removeClass('active');
    });
  }
}


$(document).ready(function() {
    Android.scroll.init();
    Android.menu.init();
});
